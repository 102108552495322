import React, { useEffect } from 'react'
import { slideToggle } from '../../utils/globalUtils';
import { useSelector } from 'react-redux';
import { displayPrice } from '../currencyPrice';
import { TRANSLATION_TYPE } from '../../utils/constants';
import { getTranslation } from '../../reducers/translation';
import { getInterpolatedString } from '../../utils/text';

function OrderSummary() {
    const { estimate, displayPurchaseComplete } = useSelector((state) => state.cart);
    const { creditsTotal, taxAmount, discountAmount, subTotal, total, period, periodUnit, currencyCode, itemLines, meta, displayTax, subStatus, nextTotal, trialPeriod, trialPeriodUnit, thankyouPageType } = estimate;
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    useEffect(() => {
        document.querySelector('#order-summary__more').addEventListener('click', function () {
            this.classList.toggle('active');
            slideToggle(this.previousElementSibling);
        });
    }, [])

    const getPeriodText = () => {
        if (periodUnit && period) {
            if (period === 1) {
                if (periodUnit === "Month")
                    return t_label?.['checkout.label.monthly'] ?? "monthly"
                else if (periodUnit === "Year")
                    return t_label?.['checkout.label.annually'] ?? "annually"
            } else {
                return `every ${period} ${periodUnit.toLowerCase()}`;
            }
        }
        return null;
    }

    const getPeriodText2 = (periodUnit) => {
        if (periodUnit) {
            if (periodUnit === "Month")
                return t_label?.['checkout.label.month'] ?? "month"
            else if (periodUnit === "Day")
                return t_label?.['checkout.label.day'] ?? "day"
        }
        return null;
    }

    const displayTitle = () => {
        let plan = itemLines != null ? itemLines.find(line => line.type === "Plan") : null;
        if (plan == null)
            return <></>
        return <>
            <div className="order-summary__title">{plan.description}</div>
        </>
    }

    const displayItemTrial = (item) => {
        if (!item?.isTrial)
            return false;
        if (subStatus === "InTrial" && subTotal > 0)
            return false;
        return true;
    }

    const displayItemByType = (itemType, itemTitle) => {
        let items = itemLines != null ? itemLines.filter(line => line.type === itemType) : [];
        if (items == null || items.length === 0)
            return <></>
        return <>
            {
                itemTitle &&
                <div className="order-summary__addon">
                    {itemTitle}
                </div>
            }

            {
                items.map((item) => {
                    return (
                        <>
                            {
                                !(itemType === "Plan" && item.isTrial && subTotal > 0) &&
                                < div className="order-summary__item">
                                    {itemType === "Plan" ? `${periodUnit === "Month" ? t_label?.['checkout.label.monthly_cost'] ?? "Monthly Cost" : t_label?.['checkout.label.annual_cost'] ?? "Annual Cost"}` : item.description}

                                    {
                                        item.isTrial ?
                                            <span className='order-summary__free-trial'>{displayItemTrial(item) ? t_label?.['billing.label.free_trial'] : ""}</span> :
                                            <span>{displayPrice(item.amount, currencyCode)}</span>
                                    }
                                </div>
                            }
                        </>
                    )
                })
            }
        </>
    }

    const displayTaxAmount = () => {
        if (displayTax) {
            return displayPrice(taxAmount, currencyCode);
        }
        return t_label?.['checkout.label.calculated_at_payment'] ?? "Calculated at Payment";
    }

    const displayPlanMeta = (meta) => {
        const header = meta?.header;
        const features = meta?.data;
        const checkStyleClass = "text-green";

        return <>
            {
                header &&
                <div class="order-summary__heading" dangerouslySetInnerHTML={{ __html: header }} />
            }

            {
                features && features.map((data) => {
                    return <>
                        {/* <div class="mt-2 flex items-start gap-1.5">
                            <svg class={`h-4 w-4 shrink-0 ${checkStyleClass}`}><use href="/img/sprite.svg#check"></use></svg>
                            <div class="" dangerouslySetInnerHTML={{ __html: data.header }} />
                        </div> */}

                        <div class="order-summary__list--alt">
                            <div class="" dangerouslySetInnerHTML={{ __html: data.header }} />
                        </div>

                        <div class="order-summary__list">
                            {data.description}
                        </div>
                    </>
                })
            }
        </>
    }

    return (
        <>
            <div className="order-summary">
                <div>
                    <div className="order-summary__overline">
                        <svg width="16" height="16" viewBox="0 0 32 32" fill="none">
                            <rect width="32" height="32" fill="#21205F" />
                            <path d="M16 16H0L16 32V16Z" fill="#F2E5AF" />
                            <rect x="-11.7573" y="-20" width="100" height="6" transform="rotate(45 -11.7573 -20)" fill="white" />
                            <rect x="0.5" y="0.5" width="31" height="31" stroke="#6F7B8E" />
                        </svg>
                        VectorVest
                    </div>

                    {displayTitle()}

                    {
                        !displayPurchaseComplete && <>
                            {displayItemByType("Plan", null)}
                            {displayItemByType("Addon", t_label?.['checkout.label.addons'] ?? "ADD-ONS")}
                            {displayItemByType("Charge", t_label?.['checkout.label.charges'] ?? "CHARGES")}

                            {
                                (discountAmount > 0 && (parseFloat(total) == parseFloat(subTotal) + parseFloat(taxAmount) - parseFloat(creditsTotal))) &&
                                <>
                                    <div className="order-summary__subtotal order-summary__border-top">{t_label?.['checkout.label.discount'] ?? 'Discount'}<span>{displayPrice(discountAmount, currencyCode)}</span></div>

                                    <div className="order-summary__subtotal">{t_label?.['checkout.label.subtotal'] ?? 'Subtotal'}<span>{displayPrice(subTotal, currencyCode)}</span></div>
                                </>
                            }

                            {
                                discountAmount == 0 &&
                                <div className="order-summary__subtotal order-summary__border-top">{t_label?.['checkout.label.subtotal'] ?? 'Subtotal'}<span>{displayPrice(subTotal, currencyCode)}</span></div>
                            }

                            {
                                (discountAmount > 0 && (parseFloat(total) < parseFloat(subTotal) + parseFloat(taxAmount) - parseFloat(creditsTotal))) &&
                                <>
                                    <div className="order-summary__subtotal order-summary__border-top">{t_label?.['checkout.label.subtotal'] ?? 'Subtotal'}<span>{displayPrice(subTotal, currencyCode)}</span></div>

                                    <div className="order-summary__subtotal">{t_label?.['checkout.label.discount'] ?? 'Discount'}<span>{displayPrice(discountAmount, currencyCode)}</span></div>
                                </>
                            }

                            <div className="order-summary__subtotal">{t_label?.['checkout.label.tax'] ?? 'Tax'}<span>{displayTaxAmount()}</span></div>

                            {
                                creditsTotal > 0 &&
                                <div className="order-summary__subtotal">{t_label?.['checkout.label.credits'] ?? 'Credits'}<span>{displayPrice(creditsTotal, currencyCode)}</span></div>
                            }

                            <div className="order-summary__total">{t_label?.['checkout.label.total'] ?? 'Total'}<span>{displayPrice(total, currencyCode)}</span></div>

                            {
                                (subStatus !== "InTrial") && periodUnit && thankyouPageType != 'COURSE' && <>
                                    {/* <div className="order-summary__annualy">Subscription billed {getPeriodText()}</div> */}
                                    <div className="order-summary__annualy">
                                        {getInterpolatedString(t_message?.['checkout.message.subscription_billed_period'], {
                                            'getPeriodText()': getPeriodText().toLowerCase(),
                                        })}
                                    </div>
                                </>
                            }

                            {
                                (subStatus === "InTrial") &&
                                <>
                                    {/* <div class="order-summary__trial">{`Billed ${getPeriodText()} after trial`}<span>{displayPrice(nextTotal, currencyCode)} </span></div> */}
                                    {/* <div class="order-summary__note">Cancel anytime before your {trialPeriod} {trialPeriodUnit.toLowerCase()} trial ends</div> */}

                                    <div class="order-summary__trial">
                                        {getInterpolatedString(t_message?.['checkout.message.billing_after_trial'], {
                                            'getPeriodText()': getPeriodText().toLowerCase()
                                        })}
                                        <span>{displayPrice(nextTotal, currencyCode)} </span>
                                    </div>

                                    <div class="order-summary__note">
                                        {getInterpolatedString(t_message?.['checkout.message.cancel_trial'], {
                                            'trialPeriod': trialPeriod,
                                            'trialPeriodUnit': getPeriodText2(trialPeriodUnit)
                                        })}
                                    </div>
                                </>
                            }
                        </>
                    }

                    {
                        displayPurchaseComplete && <>
                            <div className='order-summary__addon'></div>
                            <div className="order-purchase-complete">{t_label?.['checkout.label.purchase_complete'] ?? 'Purchase Complete'}</div>
                            <div>{t_message?.['checkout.message.finish_account_creation'] ?? 'Finish account creation to access VectorVest'}</div>
                            <div className='order-summary__addon'></div>
                        </>
                    }

                </div>
                <div>
                    <div id="order-summary__spoiler" className="order-summary__spoiler">
                        {
                            displayPlanMeta(meta)
                        }
                    </div>
                    <button type="button" data-more={t_label?.['checkout.label.see_whats_included']} data-less={t_label?.['checkout.label.see_less']} id="order-summary__more" className="order-summary__more"></button>
                </div>
            </div>
        </>
    )
}

export default OrderSummary
import { createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";
import { baseURL } from "../settings.js/dev";
import { updateFlexTrack } from "./cart";
import { deepTransformKeys } from "../utils/object";
import { toSnakeCase } from "../utils/text";

const initialState = {
  isCbInitiated: false,
  cbInstance: "",
  googleMapKey: "",
  cbPublishableKey: "",
  cbRetentionAppId: null,
  hostedPageId: null,
  _3dsEnabled: false,
  paymentModule: []
};

export const chargebee = createSlice({
  name: "chargebee",
  initialState,
  reducers: {
    loadChargebee: (state, action) => {
      let { site, googleMapKey, cbPublishableKey, cbRetentionAppId, flexTrackAdvertiserId, isApplePayEnabled, isGooglePayEnabled } = action.payload;
      state.cbInstance = window.Chargebee.init({
        site: site,
        iframeOnly: true,
        publishableKey: cbPublishableKey,
        enableGTMTracking: true
      });
      window.flextrack.init(flexTrackAdvertiserId);
      state.googleMapKey = googleMapKey;
      state.cbPublishableKey = cbPublishableKey;
      state.cbRetentionAppId = cbRetentionAppId;
      state.isCbInitiated = true;
      let paymentModule = [];
      if (isApplePayEnabled)
        paymentModule = [...paymentModule, 'apple_pay'];
      if (isGooglePayEnabled)
        paymentModule = [...paymentModule, 'google_pay'];
      state.paymentModule = paymentModule;
    },
    setHostedPageId: (state, action) => {
      state.hostedPageId = action.payload.id
    }
  },
});

export const createCheckoutForNewSubscription = (payload) => async (dispatch) => {
  const response = await api.post(baseURL, "/checkout/hosted-page", payload);
  dispatch(setHostedPageId(response));
  return response;
};

export const createCheckoutForUpdateSubscription = (payload) => async (dispatch) => {
  const response = await api.post(
    baseURL,
    "/checkout/update-hosted-page",
    payload
  );
  dispatch(setHostedPageId(response));
  return response;
};

export const createCheckoutForUpdatePaymentSource = () => async (dispatch) => {
  const response = await api.get(baseURL, '/billing/update-payment');
  return response;
}

export const retrieveHostedPage = (id) => async (dispatch) => {
  const response = await api.get(baseURL, `/checkout/get-hosted-page-content/${id}`);
  dispatch(updateFlexTrack({ flexTrack: response }));
  return response;
}

export const initChargebee = () => async (dispatch) => {
  const response = await api.post(baseURL, '/site');
  dispatch(loadChargebee(response));
  return response;
};

export const createIntent = async (payload) => {
  const response = await api.post(baseURL, '/checkout/payment-intent', payload);
  return deepTransformKeys(response, toSnakeCase);
}


export const { loadChargebee, setHostedPageId } = chargebee.actions;

export default chargebee.reducer;

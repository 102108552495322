/**
 * Author: Harsh Agrawal
 * Summary: This page validates user emails. For new users, it redirects them to the new checkout page; for existing users, it redirects to the upgrade checkout page. Additionally, it parses URL search parameters including items, quantity, email, promoCode, and token. Depending on the provided items, users might be redirected to a non-eligible page.
 */



import React from 'react'
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/layout/header'
import Footer from '../../components/layout/footer'
import OrderSummary from '../../components/orderSummary';
import { validateUserEmail } from '../../reducers/auth';
import { addFormCustomFieldError, validateForm } from '../../utils/form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setCart, updateDisplayPurchaseComplete } from '../../reducers/cart';
import { createItemPayload, parseItemsFromURLSearchParams, redirectUserToBaseCurrencyURL } from '../../utils/cart';
import { focusAndOpenKeyboard } from '../../utils/globalUtils';
import { initChargebee } from '../../reducers/chargebee';
import { fetchEstimateForNewSubscription } from '../../reducers/cart';
import { displayError } from '../../reducers/snackbar';
import { logout } from '../../reducers/auth';
import { LinearProgress } from '@mui/material';
import countryList from 'react-select-country-list';
import { addGdprUpdate } from '../../reducers/accountDetails';
import localStore from '../../utils/localstore';
import { getTranslation } from '../../reducers/translation';
import { TRANSLATION_TYPE } from '../../utils/constants';
import { vvLogin2 } from '../../settings.js/dev';
import { getBaseUrl } from '../../utils/functions';
import { getInterpolatedString } from '../../utils/text';

export default function EmailValidation() {
    const [email, setEmail] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [displayPage, setdisplayPage] = useState(false);
    const { countryCode } = useSelector((state) => state.auth);
    const [country, setCountry] = useState(countryCode);
    const { gdprEmailUpdates } = useSelector((state) => state.accountDetails);
    const [baseCurrencyItems, setBaseCurrencyItems] = useState([]);

    useEffect(() => {
        console.log(window.location);
        setCountry(countryCode);
    }, [countryCode])


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { items, count } = useSelector((state) => state.cart)
    const { isCbInitiated } = useSelector((state) => state.chargebee);
    const t = useSelector(getTranslation)
    const t_label = t[TRANSLATION_TYPE.LABEL] ?? {}
    const t_message = t[TRANSLATION_TYPE.MESSAGE] ?? {}

    const options = useMemo(() => countryList().getData(), [])
    const gdprCountry = ["Austria", "Belgium", "Bulgaria", "Croatia", "Cyprus", "Czechia", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Romania", "Slovakia", "Slovenia", "Spain", "Sweden", "Canada"];

    useEffect(() => {
        dispatch(logout());
        focusAndOpenKeyboard(document.getElementById('email'), 300);
        initializeCart(searchParams);
        dispatch(updateDisplayPurchaseComplete({ 'displayPurchaseComplete': false }))
        if (!isCbInitiated) {
            dispatch(initChargebee()).catch((error) => {
                dispatch(displayError(error));
            })
        }
    }, [])

    const initializeCart = (searchParams) => {
        let { cart, count, promoCode, userEmail, token } = parseItemsFromURLSearchParams(searchParams);
        dispatch(setCart({ count, "items": cart, promoCode }));

        if (userEmail || token) {

            setEmail(userEmail);
            if (token)
                localStorage.setItem('token', token);

            const emailPresent = userEmail != null && userEmail !== '';

            setTimeout(() => {
                let payload = { email: userEmail, items: createItemPayload(cart, count) }
                dispatch(validateUserEmail(payload))
                    .then((response) => redirectUser(response, !emailPresent))
                    .catch((error) => {
                        setdisplayPage(true);
                        setTimeout(() => {
                            validateForm("email_validation_form");
                        }, 10);
                        if (token)
                            localStorage.removeItem('token');
                        dispatch(displayError(error));
                    });
            }, 200);
        } else {
            setdisplayPage(true);
        }

        let estimatePayload = {
            items: createItemPayload(cart, count)
        }
        if (promoCode)
            estimatePayload = { ...estimatePayload, promoCode };

        if (userEmail) {
            estimatePayload = { ...estimatePayload, email: userEmail };
        }
        dispatch(fetchEstimateForNewSubscription(estimatePayload)).then((response) => {
        }).catch((error) => {
            setTimeout(() => {
                dispatch(displayError(error));
            }, 200);
        })
    }

    const updateEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleSwitchCurrency = (e) => {
        redirectUserToBaseCurrencyURL(baseCurrencyItems?.items);
    }

    const displayBaseCurrencyModal = (items, baseCurrency) => {
        document.body.style.overflow = 'hidden';
        document.getElementById('switch-currency').classList.add('active');
        setBaseCurrencyItems({ items, baseCurrency });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        validateAndSubmit(email, items, count, true)
            .then((response) => {
                redirectUser(response, false)
            })
            .catch((error) => dispatch(displayError(error)));
    }

    const validateAndSubmit = async (email, items, count, validateForm_) => {
        if (validateForm_ && !validateForm("email_validation_form"))
            return;

        let payload = { email, items: createItemPayload(items, count) }
        return dispatch(validateUserEmail(payload));
    }

    const signInOption = (loginLink) => {
        return `<div>${getInterpolatedString(t_label['checkout.label.existing_user_sign_in'], { 'loginLink': loginLink })}</div>`
    }

    const redirectUser = (response, skipLogin) => {
        let { userStatus, status, description, message, getCards, isBaseCurrencyDifferent, username } = response;

        if (isBaseCurrencyDifferent) {
            setdisplayPage(true);
            setTimeout(() => {
                displayBaseCurrencyModal(response?.items, response?.baseCurrency);
            }, 200);
            return;
        }

        if (userStatus === "EXIST") {
            if (status === "success") {
                if (skipLogin)
                    navigate("/checkout/viewbillingdetails");
                else {
                    displayLoginOption("/checkout/viewbillingdetails", username)
                }
            } else if (status === "error")
                navigate("/checkout/noteligible", { state: { message, description } })
        }
        else if (userStatus === "NEW") {
            if (status === "error")
                navigate("/checkout/noteligible", { state: { message, description } })
            else
                navigate('/checkout/addbillingdetails', { state: { getCards } })
        } else if (userStatus === "EXIST_NO_SUB") {
            if (status === "success") {
                if (skipLogin)
                    navigate('/checkout/addbillingdetails', { state: { getCards } })
                else {
                    displayLoginOption("/checkout/addbillingdetails", username)
                }
            } else if (status === "error")
                navigate("/checkout/noteligible", { state: { message, description } })
        }
        setdisplayPage(true);
    }

    const displayLoginOption = (redirectUrlPath, username) => {
        var redirectUrl = vvLogin2 + getBaseUrl() + redirectUrlPath;
        if (username != null)
            redirectUrl += `&email=${username}`;
        addFormCustomFieldError("email_validation_form", document.getElementById("email"), signInOption(redirectUrl));
        // navigate('/checkout/login')
    }

    const isGDPRCountry = () => {
        let gdpr_country = options.find((option_ => option_.value === country));
        var value = gdprCountry.includes(gdpr_country?.label);
        if (value)
            dispatch(addGdprUpdate({ gdprEmailUpdates: gdprEmailUpdates ?? false }))
        else
            dispatch(addGdprUpdate({ gdprEmailUpdates: undefined }))
        return value;
    }

    const updateGDPRCheckbox = (e) => {
        dispatch(addGdprUpdate({ gdprEmailUpdates: e.target.checked }))
    }

    return (
        <>
            {
                !displayPage &&
                <LinearProgress />
            }

            <>
                <div className={displayPage ? 'body body--alt2' : 'hidden'}>
                    <Header />

                    <main className="main main--fullh">
                        <section className="section personal-details">
                            <div className="grid-cont">
                                <form onSubmit={handleSubmit} noValidate id="email_validation_form" className="personal-details__form">
                                    <h2 className="personal-details__heading mb-8">{t_label?.['checkout.label.tell_us_your_email'] ?? 'Tell us your email'}</h2>

                                    <label className="personal-details__label">{t_label?.['checkout.label.email'] ?? 'Email'}</label>
                                    <div className="form-group personal-details__control personal-details__control--hastip">
                                        <input required disabled="" type="email" value={email} name="email" id="email" className="personal-details__input" onChange={updateEmail} data-pristine-maxLength="70" data-pristine-maxlength-message={t_message?.['checkout.message.email_min_length'] ?? "Email cannot have more than 70 characters"} data-pristine-required-message={t_message?.['checkout.message.required'] ?? 'This field is required'} data-pristine-email-message={t_message?.['checkout.message.valid_email'] ?? 'This field requires a valid e-mail address'} />
                                    </div>

                                    <div className="form-group personal-details__control">
                                        <label htmlFor="country" className="personal-details__label">{t_label?.['checkout.label.country'] ?? 'Country'}</label>

                                        <select required name="country" id="country" defaultValue={country} value={country} className="personal-details__select"
                                            onChange={(e) => setCountry(e.target.value)}
                                        >
                                            {options.map(option_ =>
                                                <option key={option_.value} value={option_.value}>{option_.label}</option>
                                            )}
                                        </select>

                                    </div>

                                    {
                                        isGDPRCountry() &&
                                        <label class="text-md form-group personal-details__checkbox">
                                            <input type="checkbox" id="gdpr" name="gdpr" data-pristine-required-message={t_message?.['checkout.message.mandatory_checkbox'] ?? 'Please check this box to proceed'} onChange={updateGDPRCheckbox} />
                                            <span>{t_message?.['checkout.message.marketing_email'] ?? 'Sign up to receive VectorVest updates and marketing via email. You may unsubscribe at any time.'}</span>
                                        </label>
                                    }

                                    <button type="submit" className="button personal-details__button">{t_label?.['checkout.button.continue'] ?? 'Continue'}</button>

                                </form>
                                <OrderSummary />
                            </div>
                        </section>
                    </main>

                    <Footer />
                </div>


                <section id="switch-currency" className="modal">
                    <div className="modal__back"></div>
                    <button type="button" className="modal__close"></button>
                    <div className="modal__frame">
                        <div className='form-header'>
                            <h4>Switch Currency</h4>
                        </div>

                        <div className="modal__cont">
                            <div>{`Your account is set up to pay in ${baseCurrencyItems?.baseCurrency}`}</div>
                            <p>{`Click the button to be redirected to our ${baseCurrencyItems?.baseCurrency} site`}</p>
                        </div>

                        <div className='form-footer'>
                            <div onClick={handleSwitchCurrency} className='form-footer__action_button bg-2'>
                                {`LOAD ${baseCurrencyItems?.baseCurrency} SITE`}
                            </div>
                        </div>
                    </div>
                </section>

            </>
        </>
    )
}
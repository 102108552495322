export const CHECKOUT_STATUS = {
    INITIATED: 'INITIATED',
    COMPLETED: 'COMPLETED'
}

export const TRANSLATION_FILE_NAME = {
    LABEL: 'UILabels.CSV',
    MESSAGE: 'UIMessages.CSV'
}

export const TRANSLATION_TYPE = {
    LABEL: "LABEL",
    MESSAGE: "MESSAGE"
}
import { createSlice } from "@reduxjs/toolkit";
import { baseURL } from "../settings.js/dev";
import api from "../utils/api";
import { updateFlexTrack } from "./cart";

const initialState = {
    subscription: {
        plan: null,
        renewal: null,
        perMonth: null,
        perAnnual: null,
        billingPeriodUnit: null,
        card: null,
        currencyCode: null,
        status: null,
        subscriptionId: null,
        customerId: null,
        email: null,
        pausedAt: null,
        cancelledAt: null,
        trialEnd: null,
        currentTermEnd: null,
        channel: null,
        nextBillingAmount: null
    },
    invoices: [],
    currencyCode: null,
    upgradableSubscription: {},
    courses: [],
    isCTInvoiceDunning: false,
    paymentSources: []
}

export const billingDetails = createSlice({
    name: 'billingDetails',
    initialState,
    reducers: {
        setSubscription: (state, action) => {
            let { plan, renewal, perMonth, perAnnual, billingPeriodUnit, card, currencyCode, status, cancelReason, subscriptionId, customerId, email, pausedAt, cancelledAt, trialEnd, currentTermEnd, channel, nextBillingAmount } = action.payload;
            let subscription = { plan, renewal, perMonth, perAnnual, billingPeriodUnit, card, currencyCode, status, cancelReason, subscriptionId, customerId, email, pausedAt, cancelledAt, trialEnd, currentTermEnd, channel, nextBillingAmount };
            state.subscription = subscription;
        },
        setInvoices: (state, action) => {
            let { invoices, currencyCode, isCTInvoiceDunning } = action.payload;
            state.invoices = invoices;
            state.currencyCode = currencyCode;
            state.isCTInvoiceDunning = isCTInvoiceDunning;
        },
        setUpgradableSubscription: (state, action) => {
            let { plan, addons, charges, courses, currencyCode } = action.payload;
            let upgradableSubscription = { plan, addons: addons?.addons, charges, currencyCode };
            state.upgradableSubscription = upgradableSubscription;
            state.courses = courses;
        },
        setCourses: (state, action) => {
            // state.courses = action.payload;
        },
        setPaymentSources: (state, action) => {
            state.paymentSources = action.payload.paymentSources;
        }
    }
});

export const fetchSubscription = () => async (dispatch) => {
    const response = await api.get(baseURL, '/billing/subscription');
    dispatch(setSubscription(response));
    return response;
}

export const fetchInvoices = () => async (dispatch) => {
    const response = await api.get(baseURL, '/billing/invoices');
    dispatch(setInvoices(response));
    return response;
}

export const downloadInvoice = (invoiceId, type) => async (dispatch) => {
    const response = await api.get(baseURL, `/billing/invoices/${invoiceId}/download?type=${type}`);
    return response;
}

export const updateCard = (payload) => async (dispatch) => {
    const response = await api.post(baseURL, "/billing/card/update", payload);
    dispatch(setSubscription(response));
    return response;
}

export const fetchUpgradableSubscription = () => async (dispatch) => {
    const response = await api.get(baseURL, '/billing/upgradable');
    dispatch(setUpgradableSubscription(response));
    return response;
}

export const createSubscription = (payload, recaptchaToken) => async (dispatch) => {
    const response = await api.post(baseURL, '/checkout/upsert-subscription', payload, {
        'headers': { 'X-Captcha-Token': recaptchaToken }
    });
    dispatch(updateFlexTrack(response));
    return response;
}

export const reactivateSubscription = () => async (dispatch) => {
    const response = await api.post(baseURL, '/billing/reactivate');
    dispatch(setSubscription(response));
    return response;
}

export const pauseSubscription = (payload) => async (dispatch) => {
    const response = await api.post(baseURL, '/billing/pause', payload);
    dispatch(setSubscription(response));
    return response;
}

export const resumeSubscription = () => async (dispatch) => {
    const response = await api.post(baseURL, '/billing/resume');
    dispatch(setSubscription(response));
    return response;
}

export const fetchCourses = () => async (dispatch) => {
    const response = await api.get(baseURL, '/billing/courses');
    dispatch(setCourses(response));
    return response;
}

export const listPaymentSource = () => async (dispatch) => {
    const response = await api.get(baseURL, '/billing/list-payment');
    dispatch(setPaymentSources({ 'paymentSources': response }));
    return response;
}

export const { setSubscription, setInvoices, setUpgradableSubscription, setCourses, setPaymentSources } = billingDetails.actions;

export default billingDetails.reducer;